import './index.css';
import React, { useRef, useState, useEffect, useMemo } from 'react';
import { ChevronLeft, ChevronRight, ChevronUp, ChevronDown } from 'lucide-react';


const HeaderCard = ({ title, subtitleFirstLine, subtitleSecondLine, backgroundColor = 'bg-red-400' }) => {
  const [firstLine, secondLine] = title.split(' ');

  return (
    <div className={`${backgroundColor} relative overflow-hidden p-8`}>
      <div className="relative z-10 pt-20 pb-20">
        <h1 className="text-3 text-white tracking-letter-space-wide-1 leading-none mb-2">
          {firstLine.toUpperCase()}<br />
          {secondLine.toUpperCase()}
        </h1>
        <p className="text-1.5 text-white">
          {subtitleFirstLine} <br />
          {subtitleSecondLine}
        </p>
      </div>
      <div className="absolute top-0 right-0 h-full">
        <img
          src="/grape/grape-outline.svg"
          alt="Grape outline"
          className="h-full object-cover"
        />
      </div>
    </div>
  );
};


const ProductCard = ({ product }) => (
  <a
    href={product.webshopUrl}
    target="_blank"
    rel="noopener noreferrer"
    className={`
      rounded-lg flex-shrink-0 flex flex-col p-2 w-40 
      ${product.bonusMechanism ? 'p-[2px]' : 'border-2 border-white'} 
      cursor-pointer hover:shadow-lg transition-shadow duration-300
    `}
    style={product.bonusMechanism ? {
      background: 'linear-gradient(to top, #D47F43, white)',
    } : {}}
  >
    {product.bonusMechanism && (
      <div className="bg-white rounded-lg p-2 flex flex-col h-full">
        <ProductCardContent product={product} />
      </div>
    )}
    {!product.bonusMechanism && (
      <ProductCardContent product={product} />
    )}
  </a>
);

const ProductCardContent = ({ product }) => (
  <>
    <div className="h-12 flex items-center justify-center">
      <p className="text-sm font-semibold text-center line-clamp-2 text-black">{product.title}</p>
    </div>
    <div className="flex items-center justify-center mt-1 mb-3">
      <div className="bg-dark-blue text-white rounded px-2 py-1 flex items-center">
        <span className="text-500 mr-2 text-yellow">★</span>
        <span className="text-s font-bold">{product.rating.toFixed(1)}</span>
      </div>
    </div>
    <img src={product.imageUrl} alt={product.title} className="w-full h-40 object-cover mb-2" />
    <div className="flex flex-row items-center justify-center">
      <span className={`text-lg font-bold ${product.bonusMechanism ? 'text-orange' : 'text-black'}`}>
        €{product.currentPrice.toFixed(2)}
      </span>
      {product.bonusMechanism && (
        <span className="ml-2 text-sm line-through text-gray-500">€{product.priceBeforeBonus.toFixed(2)}</span>
      )}
    </div>
  </>
);

const Carousel = ({ title, subtitle, products, selectedWineType }) => {
  const carouselRef = useRef(null);

  const scroll = (scrollOffset) => {
    if (carouselRef.current) {
      carouselRef.current.scrollLeft += scrollOffset;
    }
  };

  const filteredProducts = selectedWineType === 'all'
    ? products
    : products.filter(product => product.wineType === selectedWineType);

  const sortedProducts = [...filteredProducts].sort((a, b) => {
    if (b.rating !== a.rating) return b.rating - a.rating;
    return a.currentPrice - b.currentPrice;
  });

  // If there are no products after filtering, don't render anything
  if (sortedProducts.length === 0) {
    return null;
  }

  return (
    <div className="mb-8 pl-6 pr-6">
      <h2 className="text-32 text-red-600 tracking-letter-space-wide-1">{title.toUpperCase()}</h2>
      <p className="text-1.5 mb-4 text-grey tracking-letter-space-wide-2">{subtitle}</p>
      <div className="relative">
        <div
          ref={carouselRef}
          className="flex overflow-x-auto space-x-4 pb-4 scrollbar-hide"
          style={{ scrollBehavior: 'smooth' }}
        >
          {sortedProducts.map(product => (
            <ProductCard key={product.id} product={product} />
          ))}
        </div>
        <button
          onClick={() => scroll(-200)}
          className="absolute left-0 top-2/4 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md z-10"
        >
          <ChevronLeft size={24} />
        </button>
        <button
          onClick={() => scroll(200)}
          className="absolute right-0 top-2/4 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md z-10"
        >
          <ChevronRight size={24} />
        </button>
      </div>
    </div>
  );
};

const WineTypeFilter = ({ selectedType, onSelectType }) => {
  const types = [
    { label: 'Alles', value: 'all' },
    { label: 'Rood', value: 'RED' },
    { label: 'Wit', value: 'WHITE' }
  ];

  return (
    <div className="bg-beige mb-8 p-6 ">
      <p className="text-1.5 mb-2 font-semibold text-black tracking-letter-space-wide-2-">Selecteer je voorkeur</p>
      <div className="flex space-x-2">
        {types.map(type => {
          const isSelected = selectedType === type.value;
          return (
            <button
              key={type.value}
              onClick={() => onSelectType(type.value)}
              className={`px-4 py-2 rounded-full border flex items-center ${isSelected
                  ? 'bg-red-600 text-white border-red-600'
                  : 'bg-white text-black border-red-600'
                }`}
            >
              <img
                src={isSelected ? 'grape/grape-white.svg' : 'grape/grape-black.svg'}
                alt="Grape icon"
                className="w-5 h-5 mr-2"
              />
              <span className={`'text-red-600' {isSelected ? 'text-white' : 'text-red-600'}`}> {type.label} </span>
            </button>
          );
        })}
      </div>
    </div>
  );
};


const FAQItem = ({ question, answer }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="mb-6 border-b border-gray-200 pb-4">
      <button
        className="flex justify-between items-center w-full text-left"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <p className="text-1.25 font-semibold mb-2">{question}</p>
        {isExpanded ? (
          <ChevronUp className="flex-shrink-0 ml-2" />
        ) : (
          <ChevronDown className="flex-shrink-0 ml-2" />
        )}
      </button>
      {isExpanded && (
        <p className="text-gray">{answer}</p>
      )}
    </div>
  );
};


const Footer = () => {
  const faqItems = [
    {
      question: "Hoe werkt het puntensysteem?",
      answer: "Wij hanteren het vijfsterrensysteem van Vivino voor onze wijnbeoordelingen. In tegenstelling tot veel andere websites en beoordelingen van critici, zijn de scores op Vivino volledig gebaseerd op de beoordelingen van consumenten. Deze scores worden nooit beïnvloed door advertenties, sponsoring of andere externe factoren."
    },
    {
      question: "Van welke supermarkten selecteren jullie de wijn?",
      answer: "Op dit moment selecteren wij uitsluitend wijnen van Albert Heijn. In de toekomst hopen we onze selectie uit te breiden naar andere supermarkten."
    },
    {
      question: "Waarom staan niet alle wijnen op jullie site?",
      answer: "Om ervoor te zorgen dat je altijd van een goede wijn kunt genieten, adviseren we alleen wijnen met een Vivino-score van 3.8 of hoger. Wijnen met een score van 3.8 behoren tot de beste 25% van alle wijnen op de markt."
    }
  ];

  return (
    <footer className="bg-beige p-8">
      <div className="max-w-3xl mx-auto">
        <h2 className="text-3 text-red-600 tracking-letter-space-wide-1 pb-4">FAQ</h2>
        {faqItems.map((item, index) => (
          <FAQItem key={index} question={item.question} answer={item.answer} />
        ))}
      </div>

      <div className="text-center mt-8">
        <h1 className="text-1.5 text-red-600 tracking-letter-space-wide-1">
          © SUPERMARKTSOMMELIER </h1>
      </div>
    </footer>
  );
};


const App = () => {
  const [selectedWineType, setSelectedWineType] = useState('all');
  const [wineData, setWineData] = useState([]);

  useEffect(() => {
    fetch('/wineData.json')
      .then(response => response.json())
      .then(data => setWineData(data))
      .catch(error => console.error('Error fetching wine data:', error));
  }, []);

  const categorizedWines = useMemo(() => {
    return wineData.reduce((acc, wine) => {
      const category =
        wine.currentPrice < 7.5 ? 'sub7half' :
          wine.currentPrice < 10 ? 'sub10' :
            wine.currentPrice < 15 ? 'sub15' : '15plus';

      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(wine);
      return acc;
    }, {});
  }, [wineData]);

  if (wineData.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto max-w-4xl">
      <HeaderCard title="Supermarkt sommelier" subtitleFirstLine="Nooit meer een slechte wijn" subtitleSecondLine="uit de supermarkt." />
      <WineTypeFilter selectedType={selectedWineType} onSelectType={setSelectedWineType} />
      <Carousel
        title="Wijnen < €7.5"
        subtitle="Lekker voor elke gelegenheid"
        products={categorizedWines['sub7half']}
        selectedWineType={selectedWineType}
      />

      <Carousel
        title="Wijnen €7.5 - €10"
        subtitle="Perfecte wijnen om te ontdekken"
        products={categorizedWines['sub10']}
        selectedWineType={selectedWineType}
      />

      <Carousel
        title="Wijnen €10 - €15"
        subtitle="Geniet van wijnen met karakter"
        products={categorizedWines['sub15']}
        selectedWineType={selectedWineType}
      />

      <Carousel
        title="Wijnen €15+"
        subtitle="Wijnen voor echte fijnproevers"
        products={categorizedWines['15plus']}
        selectedWineType={selectedWineType}
      />
      <Footer />
    </div>
  );
};

export default App;